"use strict"

import React, {useState} from "react"
import {observer} from "mobx-react"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import {injectIntl} from "react-intl"
import ReactTooltip from "react-tooltip"
import {Container, Grid, Paper, Typography, Link, Box} from "@material-ui/core"

import XsInput from "../../global/ui/xsInput/xsInput"
import XsNumberInput from "../../global/ui/xsInput/xsNumberInput"
import XsButton from "../../global/ui/xsButton/xsButton"
// import XsCheckbox from "../../global/ui/xsCheckbox/xsCheckbox"

import LoginStore from "./loginStore"
import RouterStore from "../../global/store/RouterStore"
import GlobalStore from "../../global/store/GlobalStore"
import {isEmailValid} from "../../global/helpers/functions"
import XsConfirmationDialog from "../../global/ui/xsDialog/xsConfirmationDialog"
import XsCheckbox from "../../global/ui/xsCheckbox/xsCheckbox"

function Register() {
	const hooks = {
		onSubmit(form) {
			const {email, company_name, company_identifier} = form.values()

			if (isEmpty(email) || isEmpty(company_name) || isEmpty(company_identifier) || !isEmailValid(email)) {
				GlobalStore.setNotificationMessage("Vyplnte všetky polia!", "", "warning")
				form.invalidate()
			}
		},
		onSuccess(form) {
			LoginStore.registration(form)
		},
		onError() {}
	}

	const [form] = useState(
		new MobxReactForm(
			{
				fields: {
					email: {label: "Firemný email", rules: ""}, //email|string
					company_name: {label: "Obchodné meno", rules: ""},
					company_identifier: {label: "IČO", rules: "between:8,8"},
					applicant: {
						label: "Mám záujem vyplniť žiadosť základné skupinové poradenstvo",
						rules: "",
						type: "checkbox",
						value: true
					},
					solver: {
						label: "Mám záujem vyplniť žiadosť o zaradenie do zoznamu oprávnených riešiteľov",
						rules: "",
						type: "checkbox"
					},
					// phone: {label: "Telefónne číslo", rules: ""},
					confirmation: {
						label: <span>Vyhlasujem, že som sa oboznámil/a s</span>,
						type: "checkbox",
						value: false
					}
				}
			},
			{plugins: {dvr: dvr(validatorjs)}, hooks}
		)
	)

	return (
		<React.Fragment>
			<div className="main_header">
				<Container style={{maxWidth: "90%"}}>
					<Grid container alignItems="center" justify="space-between" className="pt-2 pb-2">
						<Grid item xs={12}>
							<img
								src="/public/images/siea_a_inovujme.png"
								style={{width: "470px", height: "100px"}}
								className="pointer"
								onClick={() => RouterStore.push("/login")}
							/>
						</Grid>
						<Grid item xs={12} className="header_gradient" style={{textAlign: "center"}}>
							<Typography variant="h3">Registrácia pre zapojenie do Výziev projektu Inovujme</Typography>
						</Grid>
						{/* <Grid item style={{width: 470}}></Grid> */}
					</Grid>
				</Container>
			</div>
			<Container maxWidth="lg" className="mt-4">
				<Grid container justify="center">
					<Grid item xs={12}>
						<Grid container direction="column" justify="center">
							<Paper variant="elevation" elevation={2} style={{backgroundColor: "transparent"}}>
								<Box p={2}>
									<Grid item container direction="column" spacing={2}>
										<Grid item>
											<Typography variant="body2">
												{/* If you want to register on the IPCEI portal, fill in the fields{" "}
												<span className="bold">Name</span>, <span className="bold">Surname</span> and your{" "}
												<span className="bold">Email address</span>. You will receive a registration email with a
												registration link. To complete the registration click the registration link from email you will
												receive. */}
												{/* Ak sa chcete zaregistrovať na IPCEI portál, vyplňte položky Meno, Priezvisko a vašu Emailovú adresu, na
							ktorú vám bude doručený registračný email s registračnou linkou, na ktorú je potrebné kliknúť a dokončiť
							registráciu. */}
											</Typography>
										</Grid>
										<Grid item container spacing={1}>
											<Grid item xs={12} md={6}>
												<XsInput
													field={form.$("company_name")}
													autoFocus={true}
													submitAction={(e) => form.onSubmit(e)}
												/>
											</Grid>
											<Grid item xs={12} md={6} data-tip data-for="company_identifier">
												{/* <XsInput field={form.$("company_identifier")} submitAction={(e) => form.onSubmit(e)} /> */}
												<XsNumberInput field={form.$("company_identifier")} step={"1"} min={"1"} />
												<ReactTooltip className="custom-tooltip-line-height" id="company_identifier">
													Skontrolujte prosím správnosť Vaších údajov, už ich nebudete môcť zmeniť.
												</ReactTooltip>
											</Grid>
											<Grid item xs={12} md={6} className="mt-1">
												<XsInput
													field={form.$("email")}
													submitAction={(e) => form.onSubmit(e)}
													regex={(val) => isEmailValid(val)}
												/>
											</Grid>
											<Grid md={6}></Grid>
											{/* <Grid item xs={6}>
												<XsCheckbox
													field={form.$("applicant")}
													onChange={() => {
														form.$("applicant").value = true
														form.$("solver").value = false
													}}
												/>
											</Grid> */}
											{/* <Grid item xs={6}>
												<XsCheckbox
													field={form.$("solver")}
													onChange={() => {
														form.$("solver").value = true
														form.$("applicant").value = false
													}}
												/>
											</Grid> */}

											{/* <Grid item xs={12} md={6}>
												<XsInput field={form.$("phone")} submitAction={(e) => form.onSubmit(e)} />
											</Grid> */}
										</Grid>
										<Grid item>
											<div style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
												<XsCheckbox field={form.$("confirmation")} />
												<Link
													onClick={() => window.open("https://www.siea.sk/o-siea/ochrana-osobnych-udajov/", "_blank")}
													style={{marginLeft: "-11px", marginBottom: "1px"}}
												>
													Informáciou o spracúvaní osobných údajov
												</Link>
											</div>
										</Grid>
										<Grid item>
											<XsButton
												disabled={GlobalStore.isRegistrationClose() || form.$("confirmation").value === false}
												text={"Registrovať"}
												onClick={form.onSubmit}
											/>
										</Grid>
										<Grid item>
											<Link variant="body2" className="pointer" onClick={() => RouterStore.push("/login")}>
												Prihlásiť sa
											</Link>
										</Grid>
									</Grid>
								</Box>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
				<XsConfirmationDialog
					title={""}
					hideCancel
					confirmBtn={{
						icon: <i className="fal fa-check" />,
						text: "Common.btn.ok",
						type: "success"
					}}
					name="xsConfirmRegisterSuccess"
					text="Registrácia prebehla úspešne. Skontrolujte Vašu e-mailovú schránku, príp. SPAM."
				/>
			</Container>
		</React.Fragment>
	)
}

export default injectIntl(observer(Register))

