// import {FormattedMessage} from "react-intl"
import React from "react"
// import {/*getPerson, */ getUser} from "../../global/helpers/actions"
// import moment from "moment"

export default {
	load() {
		return {
			fields: {
				status: {},
				version: {},
				request_id: {},
				callId: {},
				callCode: {},
				zoIPNumber: {},
				sent_date: {},
				ico: {
					label: "IČO",
					rules: "required|between:8,8"
				},
				dic: {
					label: "DIČ",
					rules: "required|between:10,10"
				},
				icdph: {
					label: "IČ DPH",
					rules: ["required", "regex:/^(?=[aA-zZ0-9]*$)(?:.{1}|.{12})$/"]
				},
				companyName: {
					label: "Obchodné meno",
					rules: "required"
				},
				companyDesc: {
					label: "Základná charakteristika Žiadateľa",
					rules: "required"
				},
				companyLegalForm: {
					label: "Právna forma",
					rules: "required"
				},
				companyIBAN: {
					label: "IBAN podnikateľského účtu Žiadateľa na preplatenie Inovačnej poukážky",
					rules: "required",
					value: "SK"
				},
				companyRegion: {
					label: "Kraj",
					rules: "required"
				},
				// companyCounty: {
				// 	label: "County",
				// 	rules: "required"
				// },
				companyStreet: {
					label: "Ulica",
					rules: "required"
				},
				companyStreetNumber: {
					label: "Číslo",
					rules: "required"
				},
				companyCity: {
					label: "Obec",
					rules: "required"
				},
				companyPSC: {
					label: "PSČ",
					rules: "required|between:5,5"
				},
				companyCorrespondenceRegion: {
					label: "Kraj"
				},
				companyCorrespondenceStreet: {
					label: "Ulica"
				},
				companyCorrespondenceCity: {
					label: "Obec"
				},
				companyCorrespondenceStreetNumber: {
					label: " Číslo"
				},
				companyCorrespondencePSC: {
					label: "PSČ",
					rules: "between:5,5"
				},
				companyAddressSameYes: {
					label: "Áno",
					type: "checkbox",
					value: true
				},
				companyAddressSameNo: {
					label: "Nie",
					type: "checkbox",
					value: false
				},
				applicantChipForm: {value: JSON.stringify([{name: "", isStatutar: false}])},
				contactPersonName: {
					label: "Titul, Meno, Priezvisko, Titul",
					rules: "required"
				},
				contactPersonPhone: {
					label: "Telefónne číslo",
					rules: "required",
					value: "+421"
				},
				contactPersonMail: {
					label: "Email",
					rules: "email|required"
				},
				contactPersonId: {},
				projectName: {
					label: "Názov inovačného projektu",
					rules: "required"
				},
				projectDescAndTarget: {
					label: "Stručný popis projektu a ciele riešenia",
					rules: "required"
				},
				projectBasicParameters: {
					label: "Východisková situácia, základné parametre riešenia a popis inovatívneho produktu/služby",
					rules: "required"
				},
				projectImplementationMethod: {
					label: "Spôsob realizácie aktivít projektu, situácia po realizácii projektu a udržateľnosť projektu",
					rules: "required"
				},
				projectAdditionalInfo: {
					label: "Doplňujúce údaje k projektu"
				},
				projectRealizationPlace: {
					label: "Miesto realizácie inovačného projektu ",
					rules: "required"
				},
				projectDeadlineCompletion: {
					label: "Lehota plnenia inovačného projektu",
					rules: "required"
				},
				potentialSolverName: {
					label: "Obchodné meno",
					rules: "required"
				},
				potentialSolverIco: {
					label: "IČO",
					rules: "required|between:8,8"
				},
				potentialSolverDic: {
					label: "DIČ",
					rules: "required|between:10,10"
				},
				potentialSolverIcdph: {
					label: "IČ DPH",
					rules: ["required", "regex:/^(?=[aA-zZ0-9]*$)(?:.{1}|.{12})$/"]
				},
				potentialSolverCompletePrice: {
					label: (
						<span>
							Celková cena Inovačného projektu podľa cenového prieskumu <sup>1</sup>
						</span>
					),
					rules: "required"
				},
				potentialSolverVoucherPrice: {
					label: (
						<span>
							Výška Inovačnej poukážky <sup>2</sup>
						</span>
					),
					rules: "required|numeric|min:2000|max:15000"
				},
				potentialSolverCofinancedPrice: {
					label: (
						<span>
							Výška spolufinancovania z vlastných zdrojov Prijímateľa v EUR <sup>3</sup>
						</span>
					),
					rules: "required"
				},
				confirmCheckbox1: {
					label: "ÁNO, vyhlasujem/e",
					type: "checkbox",
					value: false
				},
				confirmCheckbox2: {
					label: "ÁNO, vyhlasujem/e",
					type: "checkbox",
					value: false
				},
				confirmCheckbox3: {
					label: "ÁNO, vyhlasujem/e",
					type: "checkbox",
					value: false
				},
				confirmCheckbox4: {
					label: "ÁNO, vyhlasujem/e",
					type: "checkbox",
					value: false
				},
				confirmCheckbox5: {
					label: "ÁNO, vyhlasujem/e",
					type: "checkbox",
					value: false
				},
				confirmCheckbox6: {
					label: "ÁNO, vyhlasujem/e",
					type: "checkbox",
					value: false
				},
				confirmCheckbox7: {
					label: "ÁNO, vyhlasujem/e",
					type: "checkbox",
					value: false
				},
				confirmCheckbox8: {
					label: "ÁNO, vyhlasujem/e",
					type: "checkbox",
					value: false
				},
				confirmCheckbox9: {
					label: "ÁNO, vyhlasujem/e",
					type: "checkbox",
					value: false
				},
				callValidity: {},
				dropzone: {},
				skDomain: {
					label: "Doména SK RIS3 2021+ a prioritná oblasť",
					value:
						"Zdravá spoločnosť - 4-2 Inovatívne produkty (vrátane (bio)materiálov a biotechnológií), procesy a postupy v zdravotníctve"
				},
				sentMarketingDataYes: {
					label: "Áno, súhlasím",
					type: "checkbox",
					value: true
				},
				sentMarketingDataNo: {
					label: "Nie, nesúhlasím",
					type: "checkbox",
					value: false
				},
				eligibleArea: {
					label: "Oprávnená oblasť",
					rules: "required"
				},
				eligibleAreaCode: {},
				eligibleCategories: {
					label: "Oprávnené kategórie"
				},
				eligibleCategoriesCode: {},
				legitimateActivities: {
					label: "Oprávnené aktivity / zamerania",
					rules: "required"
				},
				oneCompanySubjectsIdentification: {
					label:
						"Identifikácia subjektov, ktoré tvoria so Žiadateľom Jediný podnik (ak Žiadateľ netvorí s iným subjektom jediný podnik je potrebné uviesť: „Netvorí s iným subjektom Jediný podnik“)",
					rules: "required"
				},
				companySize: {
					label: "Vyhlásenie o veľkostnej kategórii podniku",
					rules: "required"
				},
				otherSourcesSupportCheckboxYes: {
					label: "Áno",
					type: "checkbox"
				},
				otherSourcesSupportCheckboxNo: {
					label: "Nie",
					type: "checkbox"
				}
			}
		}
	}
}

