"use strict"
import React, {useEffect, useState} from "react"
import {observer} from "mobx-react"
import fields from "./fields"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import {injectIntl} from "react-intl"
import dvr from "mobx-react-form/lib/validators/DVR"
import GlobalStore from "../../global/store/GlobalStore"
import XsConfirmationDialog from "../../global/ui/xsDialog/xsConfirmationDialog"
import RequestFormStore from "./RequestFormStore"
import RouterStore from "../../global/store/RouterStore"
import RegistersCacheStore from "../../global/store/RegistersCacheStore"
import {getUserCompanyInfo} from "../../global/helpers/actions"
import {isPhoneNumberValid} from "../../global/helpers/functions"
import IP_PC_124 from "./IP_PC_124"
import IP_PTM_125 from "./IP_PTM_125"

function RequestForm(props) {
	const requiredAttachemnts = ["price", "contractScan", "bankAccountScan"]
	const [hasHelpError, SetHasHelpError] = useState(false)
	const hooks = {
		onSubmit() {
			for (let index in requiredAttachemnts) {
				if (RequestFormStore[`dropedFiles${index}`].length < 1) {
					form.$("dropzone").invalidate("minimum")
					GlobalStore.setNotificationMessage("Žiadosť nemôže byť odoslaná bez prílohy!", null, "error")
				}
			}

			if (
				form.$("otherSourcesSupportCheckboxYes").value === false &&
				form.$("otherSourcesSupportCheckboxNo").value === false
			) {
				form.$("otherSourcesSupportCheckboxYes").invalidate("allFalse")
				SetHasHelpError(true)
			}
			const voucherPrice = form.$("potentialSolverVoucherPrice").value

			if (form.$("callCode").value === "IP_PTM_125") {
				if (voucherPrice < 3000 || voucherPrice > 30000) {
					form.$("potentialSolverVoucherPrice").invalidate("wrong value")
					GlobalStore.setNotificationMessage("Nesprávna výška Inovačnej poukážky!", null, "error")
				}
			} else {
				if (voucherPrice < 2000 || voucherPrice > 15000) {
					form.$("potentialSolverVoucherPrice").invalidate("wrong value")
					GlobalStore.setNotificationMessage("Nesprávna výška Inovačnej poukážky!", null, "error")
				}
			}

			const contactPersonPhone = form.$("contactPersonPhone").value
			if (contactPersonPhone) {
				if (!isPhoneNumberValid(contactPersonPhone)) {
					form.$("contactPersonPhone").invalidate("wrong format")
					GlobalStore.setNotificationMessage("Nesprávny formát telefónneho čísla!", null, "error")
				}
			}
			try {
				let statutars = JSON.parse(form.$("applicantChipForm").value)
				if (
					statutars.some((row) => {
						return isEmpty(row.name)
					})
				) {
					form.$("applicantChipForm").invalidate("minimum")
					GlobalStore.setNotificationMessage("Žiadosť nemôže byť odoslaná bez štatutára!", null, "error")
				}

				if (
					!statutars.some((row) => {
						return row.isStatutar == true
					})
				) {
					form.$("applicantChipForm").invalidate("minimum")
					GlobalStore.setNotificationMessage("Zaškrtnite aspoň jedného člena!", null, "error")
				}
			} catch (e) {
				logger(e)
				GlobalStore.setNotificationMessage("Žiadosť nemôže byť odoslaná bez štatutára!", null, "error")
				form.$("applicantChipForm").invalidate("minimum")
			}
		},
		onSuccess(form) {
			logger("SAVE", form.values())
			RequestFormStore.generateDataAndSave(form, "final", () => {
				RouterStore.push("/requests")
				GlobalStore.loading(false)
			})
		},
		onError(form) {
			logger("CHYBY:", form.errors())
			GlobalStore.setNotificationMessage("Skontrolujte povinné polia", null, "error")
		}
	}

	const [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))
	// const [consortiumRowNumber, setConsortiumRowNumber] = useState(0)

	const initNewForm = async () => {
		if (isEmpty(RequestFormStore.callId)) {
			RouterStore.push("/calls")
		} else {
			form.$("callId").value = RequestFormStore.callId
			form.$("callCode").value = RequestFormStore.callCode
			RequestFormStore.callId = null
			RequestFormStore.callCode = null
		}

		await RegistersCacheStore.checkRegisters("/request")
		if (form.$("callCode").value === "IP_PTM_125") {
			RequestFormStore.handleFormForIP_PTM_125(form)
		} else {
			RequestFormStore.handleFormForIPSK2(form)
		}

		//Predplnenie mena a ica
		RequestFormStore.loadCompanyInfo(form)
		const company = getUserCompanyInfo() || {}
		form.$("companyName").value = company.name
		form.$("ico").value = company.identifier

		RequestFormStore.defaultFormValues = form.values()
	}

	useEffect(() => {
		RequestFormStore.formRef = form
		RequestFormStore.resetDropzones()
		if (isNotEmpty(props.id)) {
			RequestFormStore.loadCodelistAndForm(form, props.id)
		} else {
			initNewForm()
		}
	}, [])

	return (
		<React.Fragment>
			{form.$("callCode").value == "IP_PC_124" && <IP_PC_124 form={form} hasHelpError={hasHelpError} />}
			{form.$("callCode").value == "IP_PTM_125" && <IP_PTM_125 form={form} hasHelpError={hasHelpError} />}
			<XsConfirmationDialog
				name="xsCloseRequestForm"
				text="Naozaj chcete opustiť formulár a zahodť zmeny?"
				confirmBtn={{
					icon: <i className="fal fa-check" />,
					text: "Common.btn.yes"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times" />,
					text: "Common.btn.no"
				}}
				onConfirmation={() => {
					form.reset()
					RouterStore.push("/requests")
				}}
			/>
		</React.Fragment>
	)
}

export default injectIntl(observer(RequestForm))

